<template>
  <v-container style="min-height: 100vh;">
    <div class="d-flex flex-row flex-grow-1 mb-3">
      <PageTitle
        text="System Settings"
        class="ft ml-n2 font-size-sm black--text"
      />
    </div>
    <div v-if="pageLoading" class="d-flex flex-row flex-grow-1">
      <v-progress-circular indeterminate color="primary" size="30" />
    </div>
    <ValidationObserver v-else v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateSettings)">
        <div
          v-if="settings"
          class="d-flex flex-row flex-grow-1 flex-wrap  justify-space-between"
        >
          <div
            class="d-flex flex-column flex-grow-1 mr-1"
            style="flex-basis: 50%;"
          >
            <span class=" ft font-weight-bold font-size-lg"
              >Organization Details</span
            >
            <span class="ft font-weight-medium font-size-sm"
              >You can update organization details here</span
            >
            <div class="d-flex flex-column flex-grow-1 pa-2 grey lighten-4">
              <v-card class=" box-shadow-light pa-3">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(updateOrganization)">
                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-text-field
                          autofocus
                          outlined
                          v-model="organization.name"
                          prepend-icon="account_circle"
                          label="Organization Name"
                          class=" ft font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        rules="required|numeric"
                        v-slot="{ errors }"
                        class="d-flex flex-grow-1 ma-1"
                      >
                        <v-text-field
                          autofocus
                          prepend-icon="phone"
                          outlined
                          :error-messages="errors[0]"
                          v-model="organization.telephoneNumber"
                          label="Telephone Number"
                          class=" ft font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-textarea
                          autofocus
                          outlined
                          prepend-icon="people"
                          v-model="organization.address"
                          label="Address"
                          class=" ft font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                    </div>
                    <div
                      class="d-flex flex-row flex-grow-1"
                      style="justify-content: flex-end;"
                    >
                      <v-btn
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :disabled="loading || pageLoading"
                        class="ft font-weight-medium font-size-md"
                      >
                        Save
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card>
            </div>

            <span class=" ft font-weight-bold font-size-lg mt-2">Account</span>
            <span class="ft font-weight-medium font-size-sm mb-2"
              >Update personal information here</span
            >
            <div class="d-flex flex-column flex-grow-1 pa-2 grey lighten-4">
              <v-card class=" box-shadow-light pa-3">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(updateProfile)">
                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-text-field
                          autofocus
                          outlined
                          v-model="user.name"
                          prepend-icon="account_circle"
                          label="Full Name"
                          class=" ft font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                      >
                        <v-text-field
                          autofocus
                          prepend-icon="email"
                          outlined
                          v-model="user.email"
                          label="Email Address"
                          disabled
                          class=" ft font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="people"
                          v-model="user.username"
                          label="Username"
                          class=" ft font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                      >
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="phone"
                          v-model="user.mobile"
                          label="Phone Number"
                          disabled
                          class=" ft font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div
                      class="d-flex flex-row flex-grow-1"
                      style="justify-content: flex-end;"
                    >
                      <v-btn
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :disabled="loading || pageLoading"
                        class="ft font-weight-medium font-size-md"
                      >
                        Save
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card>
            </div>

            <template v-if="settings && settings.display">
              <div
                v-for="(display, index) in settings.display"
                :key="`${index}-1`"
                class="mt-5"
              >
                <div
                  class=" d-flex flex-row ft font-weight-bold font-size-lg mt-1"
                >
                  Appearance &amp; Display
                  <div class="mt-1">
                    <i class="material-icons-outlined ml-3">{{
                      display.icon
                    }}</i>
                  </div>
                </div>

                <div class="ft font-weight-medium font-size-sm black--text">
                  Change the display of your application. Choose how Pega should
                  look to you.Select from the list of available themes
                </div>
                <div class="mt-3 ft font-weight-medium font-size-md">
                  {{ display.title }}
                </div>
                <div class="d-flex flex-row flex-grow-1">
                  <div
                    v-for="(variant, index) in themes"
                    :key="index"
                    @click="
                      chooseTheme({
                        title: variant.title
                      })
                    "
                    class="d-flex white flex-row box-shadow-light ma-1  cursor-pointer"
                    style="flex-basis: 33%; min-height: 220px;cursor:pointer;"
                  >
                    <template v-for="(color, idx) in variant.colors">
                      <!-- :class="[variant.isActive ? 'is-selected' : '']" -->
                      <div
                        class="d-flex flex-column flex-grow-1 card-radius"
                        :class="[
                          isThemeSelected.find(
                            item => item.title === variant.title
                          )
                            ? 'is-selected'
                            : ''
                        ]"
                        :key="idx"
                      >
                        <div class="d-flex flex-column flex-grow-1">
                          <div
                            class="d-flex flex-row  pa-4"
                            :style="{ backgroundColor: `${color.primary}` }"
                          ></div>
                          <div class="d-flex flex-row flex-grow-1"></div>
                        </div>
                        <v-divider />
                        <div class="d-flex flex-row pa-2">
                          <span class="ft font-weight-medium font-size-md">{{
                            variant.title
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <span
                class="mt-1 pl-1 red--text text--darken-1 ft font-weight-medium font-size-sm font-italic"
                >NB. Themes take a while to take effect else you'll have to
                refresh the page after updating your settings</span
              >
            </template>
            <template v-if="settings && settings.accounts">
              <div
                v-for="(setting, indx) in settings.accounts"
                :key="indx"
                class="mt-3"
              >
                <span class=" ft font-weight-bold font-size-lg mt-1"
                  >Notifications &amp; Alerts</span
                >
                <div class="ft font-weight-medium font-size-sm mt-1">
                  {{ setting.description }}
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div class="d-flex flex-column mt-n2 ">
                    <v-switch inset v-model="isNotificationTurnedOn">
                      <template v-slot:label>
                        <span
                          class="ft black--text font-weight-medium font-size-md"
                          >Turn On {{ setting.title }}</span
                        ><i class="material-icons-outlined ml-3 black--text">{{
                          setting.icon
                        }}</i>
                      </template>
                    </v-switch>
                    <span class="ft font-weight-medium font-size-sm mt-n2">{{
                      setting.subTitle
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="settings && settings.accounts">
              <div class="mt-3 pb-10">
                <span class=" ft font-weight-bold font-size-lg mt-1"
                  >System Preferences</span
                >
                <div class="ft font-weight-medium font-size-sm mt-1">
                  Change system settings to suit your needs. Set days length for
                  sales recordings, system currency etc.
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  <div class="d-flex flex-column mb-2">
                    <span
                      class="grey--text text--darken-3 font-weight-bold font-size-md ft mt-2"
                      >Default System Currency</span
                    >
                    <span
                      class="black--text ft font-weight-medium font-size-md"
                      >{{
                        settings.currency && settings.currency.length > 0
                          ? `${settings.currency[0].code} ${settings.currency[0].symbol}`
                          : "GHS"
                      }}</span
                    >
                    <v-autocomplete
                      dense
                      :items="currencies"
                      hide-details
                      outlined
                      item-value="code"
                      item-text="name"
                      return-object
                      v-model="choosenCurrency"
                      class="mt-2 ft font-weight-medium "
                      label="Change default system currency"
                      hint="Choose from a list of currencies that applies to you."
                      persistent-hint
                    >
                      <template v-slot:item="{ item }">
                        <span class="ft font-weight-medium"
                          >{{ item.name }} - {{ item.code }}</span
                        >
                      </template>
                    </v-autocomplete>
                  </div>
                  <div class="d-flex flex-column">
                    <ValidationProvider v-slot="{ errors }">
                      <v-text-field
                        dense
                        :error-messages="errors[0]"
                        hide-details
                        outlined
                        class="mt-2 ft font-weight-medium "
                        v-model="settings.daysAllowedForRecordingFuelSales"
                        label="Days within which fuel sales can be recorded"
                        hint="Set length of days within which a day-to-day sales of fuels can be recorded"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-column mt-2">
                    <ValidationProvider
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        hide-details
                        outlined
                        :error-messages="errors[0]"
                        class="mt-2 ft font-weight-medium "
                        v-model="settings.daysAllowedForRecordingShopSales"
                        label="Days within which shop sales can be recorded"
                        hint="Set length of days within which a day-to-day sales of shops can be recorded"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </div>
                </div>
                <div class="pt-5 pb-3">
                  <span
                    class="grey--text text--darken-3 font-weight-bold font-size-md ft "
                    >Default Sales Approval</span
                  >

                  <div class="d-flex flex-column flex-grow-1">
                    <div class="d-flex flex-column mt-n2 ">
                      <v-switch inset v-model="approvals.approvalOfFinalSales">
                        <template v-slot:label>
                          <span
                            class="ft black--text font-weight-medium font-size-md"
                            >Turn On Approval Of Final End Of Day Sales </span
                          ><i class="material-icons-outlined ml-3 black--text"
                            >done</i
                          >
                        </template>
                      </v-switch>
                      <span class="ft font-weight-medium font-size-sm mt-n2"
                        >Allow closing of all end-of-day activity sales to be
                        reviewed and approved by operations manager or admin
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1">
                    <div class="d-flex flex-column mt-n2 ">
                      <v-switch inset v-model="approvals.approvalOfLubeSales">
                        <template v-slot:label>
                          <span
                            class="ft black--text font-weight-medium font-size-md"
                            >Turn On Approval Of Lube Sales </span
                          ><i class="material-icons-outlined ml-3 black--text"
                            >done</i
                          >
                        </template>
                      </v-switch>
                      <span class="ft font-weight-medium font-size-sm mt-n2"
                        >Allow closing of end-of-day lube sales to be reviewed
                        and approved by operations manager or admin
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1">
                    <div class="d-flex flex-column mt-n2 ">
                      <v-switch inset v-model="approvals.approvalOfShopSales">
                        <template v-slot:label>
                          <span
                            class="ft black--text font-weight-medium font-size-md"
                            >Turn On Approval Of Shop Sales </span
                          ><i class="material-icons-outlined ml-3 black--text"
                            >done</i
                          >
                        </template>
                      </v-switch>
                      <span class="ft font-weight-medium font-size-sm mt-n2"
                        >Allow closing of end-of-day shop sales reviewed and
                        approved by operations manager or admin
                      </span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column mt-3">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="loading"
                    :loading="loading || pageLoading"
                  >
                    <span class="ft font-weight-medium font-size-md"
                      >Apply Changes</span
                    >
                  </v-btn>
                </div>
              </div>
            </template>
          </div>
          <div
            class="d-flex flex-column flex-grow-1"
            style="flex-basis: 20%;"
          ></div>
        </div>
      </form>
    </ValidationObserver>

    <v-snackbar
      v-model="showSnackBar"
      top
      right
      :timeout="4000"
      :color="status"
    >
      <span class="white--text font font-size-md font-weight-medium">{{
        message
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" color="white" @click="cancel">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import WidgetMixins from "../../../mixins/WidgetMixins";
import { cloneDeep } from "lodash";
import UAParser from "ua-parser-js";
extend("required", {
  ...required,
  message: `Input field is required`
});
extend("numeric", {
  ...numeric,
  message: `Field accept only numbers`
});
export default {
  name: "SystemSettings",
  components: {
    PageTitle: () => import(`../../../components/PageTitle`),
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      currentTheme: "settings/getCurrentTheme",
      systemSettings: "settings/getSystemSettings",
      profile: "auth/getProfileDetails",
      loading: "getIsLoading",
      pageLoading: "getIsPageLoading",
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      currencies: "settings/getAllCurrencies",
      approvalSettings: "settings/getApprovalSettings"
    })
  },
  mixins: [WidgetMixins],
  data() {
    return {
      user: {},
      settings: {},
      themes: [],
      isThemeSelected: [],
      choosenCurrency: null,
      isNotificationTurnedOn: false,
      isNotificationDenied: false,
      organization: {},
      approvals: {}
    };
  },
  watch: {
    systemSettings(payload) {
      this.settings = cloneDeep(payload);
      this.themes = this.settings.display
        .map(display => display.variants)
        .reduce((acc, items) => acc.concat(items), []);
      this.isThemeSelected = [this.themes.find(item => item.isActive)];
      this.$store.commit(
        "settings/UPDATE_CURRENT_THEME",
        this.themes.find(item => item.isActive)
      );
      this.organization = this.settings.organizationId;
    },
    approvalSettings(payload) {
      this.approvals = { ...payload };
    },
    profile(payload) {
      this.user = { ...payload };
    },
    // eslint-disable-next-line no-unused-vars
    isNotificationTurnedOn(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.turnOnNotification();
      }
    }
  },

  methods: {
    turnOnNotification() {
      Notification.requestPermission()
        .then(response => {
          switch (response) {
            case "granted":
              this.isNotificationTurnedOn = true;
              this.isNotificationDenied = false;
              this.getMessagingToken();
              this.$store.dispatch("settings/notifications", {
                take: 20,
                offset: 0
              });
              return;
            case "denied":
              this.isNotificationDenied = true;
              this.isNotificationTurnedOn = false;
              this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
              this.$store.commit(
                "UPDATE_MESSAGE",
                "Permission denied, system won't be able to send notifications",
                { root: true }
              );
              this.$store.commit("UPDATE_STATUS", "red", { root: true });
              return;
            default:
              break;
          }
        })
        .catch();
    },
    getMessagingToken() {
      //firebase messaging
      const messaging = firebase.messaging();
      messaging
        .getToken({
          vapidKey:
            "BMqnCFc7hEVVHi7hgsoRUqTSgCLljmwlw-NuGBEzESnUyq3eWgv17D8VHR33yGhLz8o3JylRTit2SlTmC7oL0Tg"
        })
        .then(currentToken => {
          if (currentToken) {
            const parser = new UAParser(
              window.navigator.userAgent.toLowerCase()
            );

            let payload = {
              browser:
                window.navigator.brave &&
                window.navigator.brave.isBrave.name === "isBrave"
                  ? "Brave"
                  : parser.getBrowser().name,
              token: currentToken
            };
            this.$store.dispatch("settings/updateNotificationToken", {
              ...payload
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    chooseTheme(payload) {
      this.isThemeSelected = this.isThemeSelected.filter(
        item => item.title.toLowerCase() === payload.title.toLowerCase()
      );
      this.isThemeSelected = [...this.isThemeSelected, payload];
      this.$store.commit(
        "settings/UPDATE_CURRENT_THEME",
        this.themes.find(item => item.title === payload.title)
      );
    },
    updateProfile() {
      this.$store.dispatch("auth/updateProfile", this.user);
    },
    updateSettings() {
      const { display } = this.settings;
      display[0]?.variants.forEach(variant => {
        variant.isActive =
          this.isThemeSelected[0].title.toLowerCase() ===
          variant.title.toLowerCase();
      });
      if (this.choosenCurrency) {
        this.settings.currency = [this.choosenCurrency];
      }
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = this.settings;
      this.$store.dispatch("settings/updateSettings", {
        settings: rest,
        approvals: this.approvals
      });
    },
    updateOrganization() {
      this.$store.dispatch("settings/updateOrganization", this.organization);
    }
  },
  created() {
    this.$store.dispatch("settings/getSettings");
    this.$store.dispatch("auth/me");
    this.$store.dispatch("settings/getApprovalSettings");
    if (this.currencies.length === 0) {
      this.$store.dispatch("settings/listOfCurrencies");
    }
    switch (Notification.permission) {
      case "default":
        this.isNotificationTurnedOn = false;
        this.isNotificationDenied = false;
        return;
      case "denied":
        this.isNotificationTurnedOn = false;
        this.isNotificationDenied = true;
        return;
      case "granted":
        this.isNotificationTurnedOn = true;
        this.isNotificationDenied = false;
        return;
      default:
        return;
    }
  }
};
</script>
<style scoped lang="scss">
.card-radius {
  border-radius: 5px;
}
.is-selected {
  border: 1px solid #0a59ab;
}
</style>
